import { isMemAccount } from "@/store/contacts/isMemAccount";
import { useAppStore } from "@/store";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { DropZone, DropItem } from "react-aria-components";
import { fileModule } from "@/modules/file";
import styled from "@emotion/styled";

export const NotesPageDragAndDrop: FC<PropsWithChildren> = ({ children }) => {
  const { store } = useAppStore();
  const [isDragging, setIsDragging] = useState(false);
  const isMemmer = isMemAccount(store.account.myAccount);

  const handleDropEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDropExit = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(async (e: { items: DropItem[] }) => {
    setIsDragging(false);
    console.log(e);
    console.log("Dropped items:", e.items);

    // Recursively get all file items from e.items
    const allFileItems = await fileModule.collectFileDropItems(e);
    // Filter for just .md files
    const markdownFileItems = allFileItems.filter(
      fileItem => fileItem.name.toLowerCase().endsWith(".md") || fileItem.type === "text/markdown"
    );

    // TODO: 1. Create a Thread sync op for this upload
    // TODO: 2. Create a Thread Event for this drop
    // TODO: 3. Upload each file with a reference to the Thread Event

    // Logging in lieu of actual implementation follows...
    console.log(`Found ${markdownFileItems.length} markdown files:`, markdownFileItems);
    if (markdownFileItems.length > 0) {
      console.log("First markdown file details:", {
        name: markdownFileItems[0].name,
        type: markdownFileItems[0].type,
      });
      // Read and log the text contents of the first markdown file
      try {
        const firstMarkdownFile = markdownFileItems[0];
        const fileText = await firstMarkdownFile.getText();
        console.log("Markdown file contents:\n", fileText);
      } finally {
        // no error handling for this test log
      }
    } else {
      console.log("No markdown files found in the dropped items");
    }
  }, []);

  if (!isMemmer) return children;

  return (
    <>
      <StyledDropZone onDropEnter={handleDropEnter} onDropExit={handleDropExit} onDrop={handleDrop}>
        {children}
        {isDragging && <DragTarget>Drop files here to upload</DragTarget>}
      </StyledDropZone>
    </>
  );
};

const StyledDropZone = styled(DropZone)`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  transition: all 0.2s ease;
`;

const DragTarget = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;
