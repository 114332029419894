import { FC, useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { DeleteSharedNotesModal } from "@/components/modal/delete-shared-notes/DeleteSharedNotesModal";
import { AddToCollectionModal } from "@/components/modal/add-to-collection/AddToCollectionModal";
import { FadeBottom } from "@/components/horizontal-scroll/FadeBottom";
import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { HomePageNotesList } from "./components/NotesListPageList";
import { NotesListPageHeader } from "./components/NotesListPageHeader";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { TrackedEvent } from "@/domains/metrics";
import { trackEvent } from "@/domains/metrics";
import styled from "@emotion/styled";
import { NotesPageDragAndDrop } from "@/pages/notes/DragAndDrop";

interface NotesListPageProps {}

export const NotesListPage: FC<NotesListPageProps> = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.notesListPage;
  const filters = page.filters;
  page.useEffects();

  /**
   * Track views of the notes list page.
   */
  useEffect(() => {
    trackEvent(TrackedEvent.NotesView, {
      lens: filters.params.lens,
      sort_by: filters.params.sortBy,
    });
  }, [filters.params.lens, filters.params.sortBy]);

  return (
    <PageWrapper>
      <AddToCollectionModal store={page.addToCollectionModal} />
      <DeleteSharedNotesModal store={page.deleteSharedNotesModal} />
      <ShareSheetModal store={page.shareSheetModal} />
      <NotesListPageHeader />
      <NotesPageDragAndDrop>
        <MdsPanelContainer>
          <HomePageNotesList />
          <FadeBottom />
        </MdsPanelContainer>
      </NotesPageDragAndDrop>
    </PageWrapper>
  );
});

NotesListPage.displayName = "NotesListPage";

const PageWrapper = styled.div`
  position: relative;
  height: 100%;
`;
