import { makeObservable } from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { UploadedFileModelData } from "./types";
import { UploadedFileObservable } from "./UploadedFileObservable";
import { SyncUpdateValue } from "@/store/sync/types";

export class AppStoreUploadedFileStore extends BaseSyncModelStore<
  UploadedFileObservable,
  UploadedFileModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.UploadedFile, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<UploadedFileModelData>): UploadedFileObservable {
    return new UploadedFileObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
