import { makeObservable } from "mobx";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { ThreadEventModelData } from "./types";
import { SyncUpdateValue } from "@/store/sync/types";

export class ThreadEventObservable extends BaseSyncModel<ThreadEventModelData> {
  public modelKind = SyncModelKind.ThreadEvent;

  constructor({
    id,
    data,
    store,
  }: { id: string; data: SyncUpdateValue<ThreadEventModelData> } & WithAppStore) {
    super({ id, data, store });

    makeObservable(this, {
      modelKind: false,
    });
  }
}
