import { makeObservable } from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { UploadedFileBatchModelData } from "./types";
import { UploadedFileBatchObservable } from "./UploadedFileBatchObservable";
import { SyncUpdateValue } from "@/store/sync/types";

export class AppStoreUploadedFileBatchStore extends BaseSyncModelStore<
  UploadedFileBatchObservable,
  UploadedFileBatchModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.UploadedFileBatch, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<UploadedFileBatchModelData>): UploadedFileBatchObservable {
    return new UploadedFileBatchObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
